<template>
  <div>
    <back-btn />
    <v-container fluid>
      <v-row justify="center">
        <v-col lg="10">
          <v-card class="card--shadow">
            <counseling-form @emitData="createCounseling" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CounselingForm from "@/components/counseling/CounselingForm";
import BackBtn from "@/components/navigation/BackBtn.vue";

export default {
  components: {
    CounselingForm,
    BackBtn,
  },
  methods: {
    createCounseling(data) {
      this.$store.dispatch("Counseling/create", data).then((response) => { 
        
           if(response!=false)
               {
                //this.$toast.success('Success');
                this.$router.push(
                    `/counseling/activity/${this.$route.params.month}/${this.$route.params.year}`
                  );
               
               }else{
                
                 this.$toast.error('Validation Failed!');
               }
         });
     
    },
    // createCounseling(data) {
    //   this.$store.dispatch("Counseling/create", data).then(() => {
    //     this.$router.push(
    //       `/counseling/activity/${this.$route.params.month}/${this.$route.params.year}`
    //     );
    //   });
    // },
  },
};
</script>
