<template>
  <v-card class="card--shadow">
    <v-card-title class="purple accent-4">
      <div class="subtitle-1 white--text">Counseling Activity Form</div>
    </v-card-title>
    <v-card-text class="pt-4">
      <form-require-noti />
      <v-row>
        <v-col lg="4">
          <label>Code <require-sign /></label>
          <small class="text-muted">&nbsp; (eg. 99 or 2)</small>
          <v-text-field
            v-model="counselingData.mdrtb_code"
            :validationMsg="handleFieldErrors($v.counselingData.mdrtb_code)"
            hide-details
            type="text"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="4">
          <label>Year <require-sign /></label>
          <small class="text-muted">&nbsp; (eg. 24)</small>
          <v-text-field
            v-model="counselingData.mdrtb_year"
            :validationMsg="handleFieldErrors($v.counselingData.mdrtb_year)"
            hide-details
            type="text"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="4">
          <label>Mono-Poly/SG<require-sign /></label>
        <v-select
          v-model="counselingData.mdrtb_monopoly"
          :items="[
            { id: 1, text: 'No' },
            { id: 2, text: 'Yes' },
            { id: 3, text: 'SG' },
          ]"
          :error="$v.counselingData.mdrtb_monopoly.$error"
          item-text="text"
          item-value="id"
          dense
          outlined
        ></v-select>
        </v-col>
        </v-row>
        <v-row>
        <v-col lg="4">
          <label>Date <require-sign /></label>
          <date-picker
            @clearDate="counselingData.date = null"
            :validationMsg="handleFieldErrors($v.counselingData.date)"
            :date="counselingData.date"
            v-model="counselingData.date"
            :min="minDate"
            :max="maxDate"
          />
        </v-col>

        <v-col md="4">
          <label>Counseling Way <require-sign /></label>
          <v-select
            :items="[
              {value:'in-person',text:'In-person'},
              {value:'by-phone',text:'By-phone'},
            ]"
            v-model="counselingData.type"
            :error-messages="handleFieldErrors($v.counselingData.type)"
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col lg="4">
          <label>Topics <require-sign /></label>
          <v-select
            :items="topicsOpt"
            multiple
            v-model="computeTopicsId"
            :error-messages="handleFieldErrors($v.counselingData.topics)"
            item-text="text"
            item-value="topic_id"
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <p class="subtitle-1">
        Number of household members
        <span class="red--text subtitle-2">
          ( You must fill in one field at below)</span
        >
      </p>
      <v-row>
        <v-col md="4">
          <label> {{under_5}} <require-sign /></label>
          <v-text-field
            v-model.trim="counselingData.less_than_5_count"
            :error-messages="
              handleFieldErrors($v.counselingData.less_than_5_count)
            "
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col md="4">
          <label>5yrs to 15yrs <require-sign /></label>
          <v-text-field
            v-model.trim="counselingData.between_5_and_14_count"
            :error-messages="
              handleFieldErrors($v.counselingData.between_5_and_14_count)
            "
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col md="4">
          <label>{{upper_15}} <require-sign /></label>
          <v-text-field
            v-model.trim="counselingData.greate_than_15"
            :error-messages="
              handleFieldErrors($v.counselingData.greate_than_15)
            "
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="px-4 pb-4">
      <v-spacer></v-spacer>
      <v-btn @click.prevent="emitData" class="form-btn">
        <v-icon left>mdi-chevron-right-circle-outline</v-icon>Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required, helpers } from "vuelidate/lib/validators";
import { ValidationMixin } from "../../mixins/ValidationMixin";
import { limitDateMixin } from "../../mixins/limitDateMixin";
import { mapState } from "vuex";
import FormRequireNoti from "@/components/notification/FormRequireNoti.vue";
import RequireSign from "@/components/notification/RequireSign.vue";
import DatePicker from "@/components/common/DatePicker.vue";

export default {
  components: { RequireSign, FormRequireNoti, DatePicker },
  mixins: [ValidationMixin, limitDateMixin],
  props: {
    counselingData: {
      type: Object,
      default: () => {
        return {
          date: null,
          type: null,
          mdrtb_code: null,
          mdrtb_year: null,
          mdrtb_monopoly:null,
          less_than_5_count: null,
          between_5_and_14_count: null,
          greate_than_15: null,
          topics: [],
        };
      },
    },
  },
  data: () => ({
    topicsOpt: [
      { topic_id: 1, text: "Treatment Adherence" },
      { topic_id: 2, text: "Psychological Support" },
      { topic_id: 3, text: "Contact Investigation" },
      { topic_id: 4, text: "Infection Control" },
      { topic_id: 5, text: "Checking Side Effect" },
      { topic_id: 6, text: "Service Directory" },
    ],
    under_5:'<5yrs',
    upper_15:'>15yrs'
  }),
  validations() {
    return {
      counselingData: {
        mdrtb_code: { required },
        mdrtb_year: { required },
        mdrtb_monopoly:{required},
        date: { required },
        type: { required },
        topics: { required },
        less_than_5_count: {
          required: this.checkHouseMemberValidation,
        },
        greate_than_15: {
          required: this.checkHouseMemberValidation,
        },
        between_5_and_14_count: {
          required: this.checkHouseMemberValidation,
        },
      },
    };
  },
  computed: {
    ...mapState("Loading", ["loading"]),
    ...mapState("Patient", ["patients"]),
    computeTopicsId: {
      get() {
        return this.counselingData.topics.map((v) => {
          if(typeof v === 'object') return Number(v.topic_id)
          return Number(v.topic_id)
        });
      },
      set(val) {
        this.counselingData.topics = val.map((v) => {
          return {topic_id:v}
        })
      },
    },
  },
  methods: {
    checkHouseMemberValidation(value) {
      if (
        this.counselingData.greate_than_15 == null &&
        this.counselingData.between_5_and_14_count == null &&
        this.counselingData.less_than_5_count == null
      )
        return false;
      //? vuelidate helpers object make false to all propertries
      //? that include in current object (eg - less_than_5_count)
      return !helpers.req(value) || true;
    },
    emitData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        const topics = this.counselingData.topics.map((v) => {
          if(typeof v === 'object') return v.topic_id
          return v
        })
       
        let data = {
          id: this.counselingData.id,
          date: this.counselingData.date,
          type: this.counselingData.type,
          less_than_5_count: this.counselingData.less_than_5_count,
          between_5_and_14_count: this.counselingData.between_5_and_14_count,
          greate_than_15: this.counselingData.greate_than_15,
          topics: topics,
          month: this.$route.params.month,
          year: this.$route.params.year,
          mdrtb_code: this.counselingData.mdrtb_code,
          mdrtb_year: this.counselingData.mdrtb_year,
          mdrtb_monopoly:this.counselingData.mdrtb_monopoly,
        };
        
       // const threeDigitPattern = /^[0-9]{3}$/;
        const twoDigitPattern = /^.*[1-9]{2}$/;
        if (
          this.counselingData.mdrtb_code && this.counselingData.mdrtb_monopoly &&
          twoDigitPattern.test(this.counselingData.mdrtb_year)
        ) {
          this.$emit("emitData", data);
        } else { 
          // if (!threeDigitPattern.test(this.counselingData.mdrtb_code))
          //   this.$toast.error(
          //     "code must has at least three digit."
          //   );
          if (!twoDigitPattern.test(this.counselingData.mdrtb_year))
            this.$toast.error("year must has at least two digit.");
          this.clearToastErrors();
        }
      }
    },
  },
  beforeMount() {
    this.counselingData.date = !this.counselingData.date
      ? this.minDate
      : this.counselingData.date;
  },
};
</script>

<style scoped></style>
